<template>
  <div
    id="box-nav"
    class="w-100"
  >
    <div
      class="navbar-container d-flex content align-items-center mt-1"
      style="background: transparent;"
    >
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            style="position: relative; left: -4px !important;"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="26"
              style="color: #ddd"
            />
          </b-link>
        </li>

        <b-link
          class="mr-0"
          :to="{ name: 'projects' }"
        >
          <span>
            <b-img-lazy
              style="height: 30px; width: 180px; left: 45px; position: absolute; top: 0px;"
              :src="collapseTogglerLogo"
              alt="logo"
              :style="collapseTogglerStyleLogo"
              class="img-brand-logo img-brand-logo-md-sm"
            />
          </span>
        </b-link>
      </ul>

      <!-- navbar md and sm -->
      <navbar-tablet class="nav-tab-md" />

      <!-- Left Col -->
      <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

      <b-navbar-nav class="nav align-items-center ml-auto">
        <user-dropdown />
      </b-navbar-nav>
    </div>

    <!-- Navbar description PAGE -->
    <title-page />

    <div
      id="bottom-nav"
      class="d-none d-xl-none"
      style="margin-left: 210px; height: 30px !important;"
    />
    <div
      id="bottom-nav1"
      class="d-none d-xl-block"
      style="margin-left: 210px; height: 30px !important;"
    />
    <div
      id="bottom-nav2"
      class="d-none d-xl-block"
      style="margin-left: 2030px;"
    />
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BAlert, BFormGroup, BFormInput, BFormCheckbox, BCardText, BButton, BImgLazy,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'
import TitlePage from './components/TitlePage.vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import NavbarTablet from './components/NavbarTablet.vue'
import { provide, computed, ref } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import { avatarText } from '@core/utils/filter'
import { db, auth, firebase } from '@/firebase'

export default {
  components: {
    BLink,
    BAlert,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BButton,
    BImgLazy,

    // Navbar Components
    BNavbarNav,
    TitlePage,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    VerticalNavMenu,
    NavbarTablet,
  },
  directives: {
    'height-fade': heightFade,
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    // console.log(this.usuario)

    const {
      // isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    // const shallShadowBottom = ref(false)

    // provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    let collapseTogglerLogo = computed(() => (collapseTogglerIcon.value === 'unpinned' ? appOnlyLogoImage : appLogoImage))
    const collapseTogglerStyleLogo = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'height:35px' : 'initial'))
    collapseTogglerLogo = computed(() => (collapseTogglerIcon.value === 'unpinned' ? appOnlyLogoImage : appLogoImage))

    // App Name
    const { appName, appLogoImage, appOnlyLogoImage } = $themeConfig.app

    return {
      // navMenuItemsAccessAdmin,
      // navMenuItemsAccessDataSpecialist,
      // navMenuItemsAccessClinicianViewer,
      // navMenuItemsAccessLogging,
      // navMenuItemsAccessShare,
      // navMenuItemsAccessClinicianActive,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      // isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      collapseTogglerLogo,
      collapseTogglerStyleLogo,

      // Shadow Bottom
      // shallShadowBottom,

      // Skin
      skin,

      // avatar
      avatarText,

      // App Name
      appName,
      appLogoImage,
      appOnlyLogoImage,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isNavClicked: false,
      // checkedSharedWithMeSites: false,
      // title: '',
      timerUpdateOpen: null
    }
  },
  methods: {
  },
  // created() {
  //   console.log(this.$route)
  // //   if(this.$route.name === 'project-sites') {
  // //     console.log(this.$route.params.id)
  // //     db.collection('projects').doc(this.$route.params.id).get()
  // //     .then(projects => {
  // //       this.title = projects.data().name
  // //     })
  // //   }
  // },
  mounted() {
    localStorage.removeItem('openMobile')
    // console.log(this.$route)
    // this.title = this.$route.params.title
    this.timerUpdateOpen = setInterval(() => {
      var openSidebarMobile = localStorage.getItem('openMobile')
      if(openSidebarMobile) {
        localStorage.removeItem('openMobile')
        this.toggleVerticalMenuActive()
      }
    }, 500)
  },
  destroyed() {
    clearInterval(this.timerUpdateOpen)
  },
}
</script>

<style>
.content.app-content {
  /* padding: calc(1rem + 4rem + 1.3rem) 2rem 0 !important; */
  padding: calc(2rem) 2rem 0 !important;
}
.text-switch {
  color: #a7aab9;
}
.custom-switch .custom-control-label::before {
  background-color: #232C51;
}
.btn-nav-page {
  /* top: 120px; */
  /* left: 1449px; */
  width: 132px;
  height: 46px;
  background: transparent linear-gradient(114deg, #7838ff 0%, #7838FF 100%) 0% 0% no-repeat padding-box;
  border-color: transparent !important;
  border-radius: 15px;
  position: absolute !important;
  right: 0px;
  top: -8px;
  padding: 0px;
  border: 0px;
  /* opacity: 1; */
}
.btn-nav-page:hover {
  /* top: 120px; */
  /* left: 1449px; */
  width: 132px;
  height: 46px;
  background: #7838ff !important;
  /* border-color: #7838ff !important; */
  border-radius: 15px;
  position: absolute !important;
  right: 0px;
  top: -8px;
  padding: 0px;
  border: 0px;
  /* opacity: 1; */
}
.btn-nav-page:active:focus {
  /* top: 120px; */
  /* left: 1449px; */
  width: 132px;
  height: 46px;
  background: #7838ff !important;
  /* border-color: #7838ff !important; */
  border-radius: 15px;
  position: absolute !important;
  right: 0px;
  top: -8px;
  padding: 0px;
  border: 0px;
  /* opacity: 1; */
}
#box-nav {
  /* position: relative; */
  /* width: 200px; */
  /* height: 50px; */
  /* background-color: blue; */
  /* border-radius: 9999px 0 0 9999px; */
  /* margin: 30px; */
  /* text-align: center; */
  color: #fff;
  /* padding-top: 10px; */
}

/* sm-md */
#bottom-nav {
  position: absolute;
  height: 30px;
  width: 30px;
  left: 60px;
  overflow: hidden;
  bottom: -30px;
}
#bottom-nav::before {
  content: '';
  position: absolute;
  left: 0;
  height: 200%;
  width: 200%;
  border-radius: 100%;
  box-shadow: 10px 10px 5px 100px #181E38;
  z-index: -1;
}

/* xl */
#bottom-nav1 {
  position: absolute;
  height: 30px;
  width: 30px;
  left: 0;
  overflow: hidden;
  bottom: -30px;
}
#bottom-nav1::before {
  content: '';
  position: absolute;
  left: 0;
  height: 200%;
  width: 200%;
  border-radius: 100%;
  box-shadow: 10px 10px 5px 100px #181E38;
  z-index: -1;
}

#bottom-nav2 {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0;
  overflow: hidden;
  bottom: -30px;
}

/* #top::before, */
#bottom-nav2::before {
  content: '';
  position: absolute;
  left: -30px;
  height: 200%;
  width: 200%;
  border-radius: 50%;
  box-shadow: 10px 10px 5px 100px #181E38;
  z-index: -1;
}
@media screen and (max-width: 2000px) {
  #bottom-nav2{
    display: none !important;
  }
}
@media (min-width: 1200px) {
  #bottom-nav{
    display: none !important;
  }
  .img-brand-logo-md-sm {
    top: 0px !important;
  }
}
@media (max-width: 1199px) {
  #bottom-nav1{
    display: none !important;
  }
}
@media (min-width: 769px) {
  .img-brand-logo-md-sm {
    top: 6px !important;
  }
}
@media (max-width: 768px) {
  .img-brand-logo-md-sm {
    top: 6px !important;
    width: 160px !important;
    height: 25px !important;
  }
  #bottom-nav{
    display: none !important;
  }
  .nav-tab-md {
    display: none !important;
  }
}
/*
#top::before {
  top: -100%;
} */
</style>
